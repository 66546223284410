export const scrollDesktop = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: "#___gatsby",
  options: {
    smooth: true,
    smoothMobile: true,
    getDirection: true,
    // touchMultiplier: 2.5,
    lerp: 0.085,
  },
}

export const scrollMobile = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: "#___gatsby",
  options: {
    smooth: false,
    smoothMobile: false,
    getDirection: true,
    touchMultiplier: 1,
    lerp: 1,
  },
}
