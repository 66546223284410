import React from "react";
import Tilty from "react-tilty";
import { Countdown } from "../01Atoms/CountDown";
import CircularText from "../02Molecules/CircularText";
import { NavigationCtr } from "../03Organisms/NavigationCtr";
import Particles1 from "../03Organisms/Particles1";
import Img from 'gatsby-image';
import { useStaticQuery } from 'gatsby';
import {isMobileOnly, isTablet} from 'react-device-detect';


export default function SectionHome(props) {
  const bgHome = ImageSupplierHome();

  function bigSize() {
    let size = props.size.width;
    console.log("size", size)


    switch(true) {
      case (size < 768):
        return "100vh";
      
      case ((size > 768) && (size < 1024)):
        return "100vh";
      
      case ((size > 1024) && (size < 1500)):
        return "110vh";
      
      case (size > 1500):
        return "130vh";
      
      default:
        return "100vh";
    }
  }

  function getFixed() {
    if (isMobileOnly) {
      return {...bgHome[0].node.childImageSharp.fixed      }
    }
    else
      return null;
  }
 
  function getFluid() {
    console.log("fluid desktop")
    if (!isMobileOnly) {
      return {...bgHome[0].node.childImageSharp.fluid, 
        aspectRatio: 4/3,
        sizes: `(max-width: 1024px) 100vw, 1400px`
      }
    }
    else
      return null;
  }

  return (
    <section className="home" id="home" data-scroll-section style={{zIndex:props.y}}>
      <NavigationCtr
        mobileMenu={props.mobileMenu}
        isMobile={props.isMobile}
        speed="5" />

      {/* <style
        dangerouslySetInnerHTML={{
          __html: [
            ".home:before {",
            '  content: "";',
            `  opacity: 0.5;`,
            `  transition: opacity 0.7s cubic-bezier(0.02, 0.13, 0, 0.99) both;`,
            "}",
          ].join("\n"),
        }}
      ></style> */}
      <Img 
        alt={bgHome[0].node.childImageSharp.fluid.originalName}
        fixed={getFixed()} //use fixed on mobile
        fluid={getFluid()} //use fluid on desktop
        loading="eager"
        fadeIn={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: isMobileOnly ? (isTablet ? "116vh" : "113vh") : bigSize(),
          marginTop: isMobileOnly ? (isTablet ? "-16vh" : "-10vh") : 0,
          overflow: "hidden",
          opacity: 0.5,
          pointerEvents: "none"
        }}
      />
      <div className="home-inner" id="home">
        <Particles1 />


        <div data-scroll data-scroll-speed="3">
          <div
            className="wow"
            data-scroll
            data-scroll-speed="2"
            data-scroll-delay="0.6" // style={{ opacity: 1 - (scrollY - 0.2), height: 0 }}
          >
            { typeof window !== 'undefined' && Tilty &&
              <Tilty
                style={{
                  transformStyle: "preserve-3d",
                  transition: "transform 0.3s ease-in-out",
                }}
                perspective={500}
                max={21}
                reset={false}
                // ease="cubic-bezier(0.02, 0.13, 0, 0.99)"
              >
                <div className="us">
                  <span className="piece">Michelle</span>
                  <span className="piece">&amp;</span>
                  <span className="piece">Tonye</span>
                </div>
              </Tilty>
            }
          </div>

          <div className="countdown" data-scroll data-scroll-speed="1">
            <CircularText message="We did it - We got married ♡ Praise Jesus. " />
            <div className="countdown-wrapper">
              {/* <Countdown
                date={new Date("March 19, 2021")}
                completeMessage="❤"
                completed
              /> */}
              
              <div className="spinning-smiley" data-scroll data-scroll-speed="2">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.com/svgjs" version="1.1" 
                preserveAspectRatio="xMidYMid meet"
                //width="50" height="50" 
                x="0" y="0" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} xmlSpace="preserve"><g><path xmlns="http://www.w3.org/2000/svg" d="m256 512c-68.38 0-132.667-26.629-181.02-74.98-48.351-48.353-74.98-112.64-74.98-181.02s26.629-132.667 74.98-181.02c48.353-48.351 112.64-74.98 181.02-74.98s132.667 26.629 181.02 74.98c48.351 48.353 74.98 112.64 74.98 181.02s-26.629 132.667-74.98 181.02c-48.353 48.351-112.64 74.98-181.02 74.98zm0-472c-119.103 0-216 96.897-216 216s96.897 216 216 216 216-96.897 216-216-96.897-216-216-216zm93.737 260.188c-9.319-5.931-21.681-3.184-27.61 6.136-.247.387-25.137 38.737-67.127 38.737s-66.88-38.35-67.127-38.737c-5.93-9.319-18.291-12.066-27.61-6.136s-12.066 18.292-6.136 27.61c1.488 2.338 37.172 57.263 100.873 57.263s99.385-54.924 100.873-57.263c5.93-9.319 3.183-21.68-6.136-27.61zm-181.737-135.188c13.807 0 25 11.193 25 25s-11.193 25-25 25-25-11.193-25-25 11.193-25 25-25zm150 25c0 13.807 11.193 25 25 25s25-11.193 25-25-11.193-25-25-25-25 11.193-25 25z" fill="#e29e84" dataOriginal="#000000" /></g></svg>
              </div>
            </div>
          </div>
           
          
        </div>
      </div>
    </section>
  );
}

/**
 * Uses GatsbyImage and GraphQL to return all images. Used for gallery section
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
const ImageSupplierHome = () => {

  // Don't forget to set the size of your image in
  // fluid(maxWidth: 300, quality: 100) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|/"}, 
        sourceInstanceName: {eq: "images"}, 
        name: {eq: "photo1cropped"}}, 
        sort: {fields: childrenImageSharp___fluid___originalName, order: ASC}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1400, srcSetBreakpoints: [ 400, 600, 960, 1300, 1400 ], quality: 90, webpQuality: 90) {
                originalName
                src
                # ...GatsbyImageSharpFluid_withWebp_tracedSVG
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed (width: 250, height: 612, webpQuality: 80, cropFocus: NORTH) {
                originalName
                # ...GatsbyImageSharpFixed_withWebp_tracedSVG
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }`);

  return ( allFile.edges );
};