import { useStaticQuery } from 'gatsby';
import React, { useRef } from "react";
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import { useIntersection } from 'react-use';
import { NavigationCtr } from "../03Organisms/NavigationCtr";
import Img from 'gatsby-image';

export default function SectionStory(props) {

  const images = ImageSupplierStory();

  const { rootMargin = '100px', threshold = 0, ...rest } = props;
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: rootMargin,
    threshold: threshold,
  });

  const inView = !(intersection && intersection.intersectionRatio > threshold);

  return <>{
    
    <div
      data-scroll-section
      id="story"
      className="content content--numbered story"
    >
      <section className="tiles tiles--rotated" id="grid2">
      {inView ?
        <div className="tiles__wrap" ref={ref}>
          <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target={!isMobile ? "#when" : "#story"} data-scroll-direction="horizontal" ></div>
          <div className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target={!isMobile ? "#grid2" : "#story"} data-scroll-direction="horizontal" ></div>
          <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target={!isMobile ? "#grid2" : "#story"} data-scroll-direction="horizontal" ></div>
          <div className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target={!isMobile ? "#grid2" : "#story"} data-scroll-direction="horizontal" ></div>
          <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target={!isMobile ? "#grid2" : "#story"} data-scroll-direction="horizontal" ></div>
        </div>
        :
        // <div className="tiles__wrap">
        //   <div
        //     className="tiles__line"
        //     data-scroll
        //     data-scroll-speed="1"
        //     data-scroll-target={!isMobile ? "#when" : "#story"}
        //     data-scroll-direction="horizontal"
        //   >
        //     {images.slice(0, 8).map(i => {
        //       return (
        //         <div
        //           key={i.node.childImageSharp.fluid.originalName}
        //           fluid={i.node.childImageSharp.fluid}
        //           className="tiles__line-img fade-in"
        //           style={{
        //             backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
        //           }}
        //         ></div>
        //       );
        //     })}
        //   </div>
        //   <div
        //     className="tiles__line"
        //     data-scroll
        //     data-scroll-speed="-1"
        //     data-scroll-target="#grid2"
        //     data-scroll-direction="horizontal"
        //   >
        //     {images.slice(9, 17).map(i => {
        //       return (
        //         <div
        //           key={i.node.childImageSharp.fluid.originalName}
        //           fluid={i.node.childImageSharp.fluid}
        //           className="tiles__line-img fade-in"
        //           style={{
        //             backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
        //           }}
        //         ></div>
        //       );
        //     })}
        //   </div>
        //   <div
        //     className="tiles__line"
        //     data-scroll
        //     data-scroll-speed="1"
        //     data-scroll-target="#grid2"
        //     data-scroll-direction="horizontal"
        //   >
        //     {images.slice(17, 25).map(i => {
        //       return (
        //         <div
        //           key={i.node.childImageSharp.fluid.originalName}
        //           fluid={i.node.childImageSharp.fluid}
        //           className="tiles__line-img fade-in"
        //           style={{
        //             backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
        //           }}
        //         ></div>
        //       );
        //     })}
        //   </div>
        //   <div
        //     className="tiles__line"
        //     data-scroll
        //     data-scroll-speed="-1"
        //     data-scroll-target="#grid2"
        //     data-scroll-direction="horizontal"
        //   >
        //        {images.slice(12, 19).map(i => {
        //       return (
        //         <div
        //           key={i.node.childImageSharp.fluid.originalName}
        //           fluid={i.node.childImageSharp.fluid}
        //           className="tiles__line-img fade-in"
        //           style={{
        //             backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
        //           }}
        //         ></div>
        //       );
        //     })}
        //   </div>
        //   <div
        //     className="tiles__line"
        //     data-scroll
        //     data-scroll-speed="1"
        //     data-scroll-target="#grid2"
        //     data-scroll-direction="horizontal"
        //   >
        //     <div className="tiles__line-img fade-in"></div>
        //     {images.slice(0, 7).map(i => {
        //       return (
        //         <div
        //           key={i.node.childImageSharp.fluid.originalName}
        //           fluid={i.node.childImageSharp.fluid}
        //           className="tiles__line-img fade-in"
        //           style={{
        //             backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
        //           }}
        //         ></div>
        //       );
        //     })}
        //   </div>
        // </div>
        <div className="tiles__wrap">
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="1"
            data-scroll-target={!isMobile ? "#when" : "#story"}
            data-scroll-direction="horizontal"
          >
            {images.slice(0, 8).map(i => {
              return (
                <Img
                  key={i.node.childImageSharp.fluid.originalName}
                  fluid={i.node.childImageSharp.fluid}
                  className="tiles__line-img"
                  style={{
                    // backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
                  }}
                ></Img>
              );
            })}
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="-1"
            data-scroll-target="#grid2"
            data-scroll-direction="horizontal"
          >
            {images.slice(9, 17).map(i => {
              return (
                <Img
                  key={i.node.childImageSharp.fluid.originalName}
                  fluid={i.node.childImageSharp.fluid}
                  className="tiles__line-img"
                  style={{
                    // backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
                  }}
                ></Img>
              );
            })}
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="1"
            data-scroll-target="#grid2"
            data-scroll-direction="horizontal"
          >
            {images.slice(17, 25).map(i => {
              return (
                <Img
                  key={i.node.childImageSharp.fluid.originalName}
                  fluid={i.node.childImageSharp.fluid}
                  className="tiles__line-img"
                  style={{
                    // backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
                  }}
                ></Img>
              );
            })}
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="-1"
            data-scroll-target="#grid2"
            data-scroll-direction="horizontal"
          >
               {images.slice(12, 19).map(i => {
              return (
                <Img
                  key={i.node.childImageSharp.fluid.originalName}
                  fluid={i.node.childImageSharp.fluid}
                  className="tiles__line-img"
                  style={{
                    // backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
                  }}
                ></Img>
              );
            })}
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="1"
            data-scroll-target="#grid2"
            data-scroll-direction="horizontal"
          >
            <div className="tiles__line-img"></div>
            {images.slice(0, 7).map(i => {
              return (
                <Img
                  key={i.node.childImageSharp.fluid.originalName}
                  fluid={i.node.childImageSharp.fluid}
                  className="tiles__line-img"
                  style={{
                    // backgroundImage: `url(${i.node.childImageSharp.fluid.srcWebp})`,
                  }}
                ></Img>
              );
            })}
          </div>
        </div>
      }
      </section>
      {!props.isMobile && (
        <NavigationCtr
          active="story"
          mobileMenu={props.mobileMenu}
          speed="5"
          sticky="true"
          target="#story" />
      )}
      <p className="content__pretitle" data-scroll data-scroll-speed="2">
        He thought she was cute. She thought he was ignoring her. She thought he
        was rude. He forgot her name. He asked her out. She arrived 10 minutes
        late. They trained together then made it official. He proposed. She said
        yes.{" "}
      </p>
      <h1 className="content__title" data-scroll data-scroll-speed="4" style={{bottom: isTablet ? "9vh" : isMobileOnly ? "16vh" : "14vh" }}>
        michelle + tonye
      </h1>
      <span
        className="number"
        data-scroll
        data-scroll-speed="3"
        onClick={() => window.scroll && window.scroll.scrollTo("#registry")}
      >
        O S
      </span>
    </div>
  }
  </>
}

/**
 * Uses GatsbyImage and GraphQL to return all images. Used for gallery section
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
export const ImageSupplierStory = () => {

  // Don't forget to set the size of your image in
  // fluid(maxWidth: 300, quality: 100) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|/"}, 
        sourceInstanceName: {eq: "images"}, 
        name: {regex: "/picture/"}}, 
        sort: {fields: childrenImageSharp___fluid___originalName, order: ASC}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 580, quality: 100) {
                originalName
                src
                srcWebp
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                #...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }`);

  return ( allFile.edges );
};