import React, {Component} from "react";

class Cursor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseX: 0,
      mouseY: 0,
      trailingX: 0,
      trailingY: 0,
    };
    this.cursor = React.createRef();
    this.cursorTrailing = React.createRef();
    this.animationFrame = null;

    this.mouseIsHover = false;
    this.cursorHoverElems = "a, button, .initials, .navigation .item, .date, .expand-gallery, .la-chevron-circle-left, .la-chevron-circle-right, .la-times";

    this.dragon = false;
    this.dragElems = ".tonye-gallery span[draggable]";
  }
  
  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    !isTouchDevice() && this.moveCursor();

    // add listener to track the current mouse hover
    document.addEventListener("mousemove", (e) => {
      //check for hover elems
      if (e.target.closest(this.cursorHoverElems)) {
        this.mouseIsHover = true;
      } else {
        this.mouseIsHover = false;
      }

      //check for drag elems
      if (e.target.closest(this.dragElems)) {
        this.dragon = true;
      } else {
        this.dragon = false;
      }
    });
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove)
    cancelAnimationFrame(this.animationFrame);
  }
  
  onMouseMove = (evt) => {
    const { clientX, clientY } = evt;
    this.setState({
      mouseX: clientX,
      mouseY: clientY,
    });
  }
  
  moveCursor = () => {
    const { mouseX, mouseY, trailingX, trailingY } = this.state;
    const diffX = mouseX - trailingX;
    const diffY = mouseY - trailingY;
    //  Number in expression is coeficient of the delay. 10 for example. You can play with it. 
    this.setState({
      trailingX: trailingX + diffX / (this.dragon ? 1 : 7), //if dragging, reduce cursor delay
      trailingY: trailingY + diffY / (this.dragon ? 1 : 7),
    },
    () => {
    // Using refs and transform for better performance.
      this.cursor.current.style.transform = `translate3d(${mouseX - 100}px, ${mouseY}px, 0)`;
      this.cursorTrailing.current.style.display = "block";
      this.cursorTrailing.current.style.transform = `translate3d(${trailingX - 4}px, ${trailingY -4}px, 0)`;
      this.animationFrame = requestAnimationFrame(this.moveCursor);
    });
  }
  
  render = () => {
    return (
      <div className="container">
        <div className="cursors">
          <div 
            className="cursor"
            ref={this.cursor}
          />
          <div 
            className={`cursor-trailer ${this.mouseIsHover && "woop"} ${this.dragon && "drag"}`}
            ref={this.cursorTrailing}
          />
        </div>
      </div>
    );
  };
}

export default Cursor;

function isTouchDevice() {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ("ontouchstart" in window || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
}