import React from "react";
import Sparkles from "../01Atoms/Sparkles";
import { NavigationCtr } from "../03Organisms/NavigationCtr";
import LottiePlayer from './../02Molecules/LottiePlayer';

export default function SectionRegistry(props) {
  return (
    <section
      data-scroll-section
      className="content content--numbered2"
      id="registry"
    >
      {!props.isMobile && (
        <NavigationCtr
          active="registry"
          mobileMenu={props.mobileMenu}
          speed="5"
          sticky="true"
          target="#registry" />
      )}
      <LottiePlayer section="registry" />
      <p className="content__text" data-scroll data-scroll-speed="2" data-scroll-target="#registry">
        We have <em>most things</em> we need for life and love. If you want to
        bless us with a gift, we can always use cash gifts as we move to our new
        place. That said, we know some people prefer to buy gifts off a{" "}
        <em>registry</em>. We created ours at Bed Bath & Beyond. Either way, we
        would appreciate your prayers and well wishes as we start this journey
        together!{" "}
      </p>
      <br />
      <br />
      <a
        className="bb-link"
        href="https://www.bedbathandbeyond.ca/store/giftRegistry/viewregistryguest/549750315?eventType=Wedding&mcid=EM_triggeredem_emailregistryplural__allpromos&selectedRLVFilters=_recommended"
        target="_blank"
        data-scroll
        data-scroll-speed="3"
        data-scroll-target="#registry"
      >
        Our wedding registry
      </a>
      <Sparkles
        style={{
          top: -180,
        }}
      ></Sparkles>

      <span
        className="number"
        data-scroll
        data-scroll-speed="3"
        onClick={() => window.scroll && window.scroll.scrollTo("#livestream")}
      >
        R
      </span>
    </section>
  );
}
