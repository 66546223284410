import { useEffect, useContext } from "react"

// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from "locomotive-scroll"
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import { scrollDesktop, scrollMobile } from "../../theme"

const Scroll = callbacks => {
  useEffect(() => {
    let scroll = null;

    if (isMobile) { 
      scroll = scrollMobile; 
    }
    if (isTablet) {
      scroll = scrollDesktop;
    }
    else {
      scroll = scrollDesktop;
    }

    let locomotiveScroll;
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })
    locomotiveScroll.update();

    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll

    locomotiveScroll.on("scroll", func => {
      // Update `data-direction` with scroll direction.
      document.documentElement.setAttribute("data-direction", func.direction);
      window.tbbY = func.scroll.y;
      localStorage.setItem('tbbY', func.scroll.y);
    })

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [callbacks])

  return null
}

export default Scroll
