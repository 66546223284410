import React from "react";
import Sparkles from "../01Atoms/Sparkles";
import { NavigationCtr } from "../03Organisms/NavigationCtr";
import LottiePlayer from './../02Molecules/LottiePlayer';

export default function SectionLivestream(props) {
  return (
    <section
      data-scroll-section
      className="content content--numbered2"
      id="livestream"
    >
      {!props.isMobile && (
        <NavigationCtr
          active="livestream"
          mobileMenu={props.mobileMenu}
          speed="5"
          sticky="true"
          target="#livestream" />
      )}
      {/* <LottiePlayer section="registry" /> */}
      <p className="content__text" data-scroll data-scroll-speed="2" data-scroll-target="#livestream">
        Thanks for joining us <em>LIVE</em> on our wedding day. We're married! The Zoom link below is no longer active. <em><a href="#">Pictures coming soon</a></em>{/*or <em><a href="#">Add our Wedding</a></em>{"  "} to your calendar. We look forward to having you join us virtually.{" "} */}
      </p>
      <br />
      <br />
      <a
        className="bb-link"
        // href="https://us04web.zoom.us/j/79004277107?pwd=V2lqTG5oSkZsRHlOUzlHU3Azc2g5dz09"
        href="#"
        target="_blank"
        data-scroll
        data-scroll-speed="3"
        data-scroll-target="#livestream"
      >
        Watch the Livestream
      </a>
      <Sparkles
        style={{
          top: -180,
        }}
      ></Sparkles>

      <span
        className="number"
        data-scroll
        data-scroll-speed="3"
        onClick={() => window.scroll && window.scroll.scrollTo("#gallery")}
      >
        L
      </span>
    </section>
  );
}
