import { useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from "react";
import Sparkles from "../01Atoms/Sparkles";
import { NavigationCtr } from "../03Organisms/NavigationCtr";

export default function SectionWhen(props) {
  const bgHome = ImageSupplierWhen();

  return <section data-scroll-section className="when" id="when" >
      {!props.isMobile && (
        <NavigationCtr
          active="when"
          mobileMenu={props.mobileMenu}
          speed="5"
          sticky="true"
          target="#story" />
      )}

      <div className="box-dad behind">
        <div
          className="box show4"
          data-scroll
          data-scroll-speed="50"
          data-scroll-target="#when"
        ></div>
      </div>

      <div className="box-dad">
        <h1
          data-scroll
          data-scroll-speed="10"
          data-scroll-direction="horizontal"
          data-scroll-target="#when"
        >
          Michelle & Tonye are having a small celebration
        </h1>
        <div
          className="box show3"
          data-scroll
          data-scroll-speed="50"
          data-scroll-target="#when"
        ></div>
      </div>

      <div className="box-dad behind">
        <div
          className="box show0"
          data-scroll
          data-scroll-speed="30"
          data-scroll-target="#when"
        ></div>
      </div>

      <div className="box-dad">
        <div
          className="box"
          data-scroll
          data-scroll-speed="6"
          data-scroll-target="#when"
          style={{
            boxShadow: "#1a160d 0px 0px 60px",
          }}
        >
          <span
            className="month"
            data-scroll
            data-scroll-speed="5"
            data-scroll-target="#when"
          >
            March
          </span>
          <div
            className="who"
            data-scroll
            data-scroll-speed="2"
            data-scroll-target="#when"
          >
            <p className="larger">19</p>
            <div style={{animation: "1.4s fade-in-top 1s cubic-bezier(0.02, 0.13, 0, 0.99)"}}>
              <Sparkles>
                <div
                  className="circle"
                  data-scroll
                  data-scroll-speed="7"
                  data-scroll-target="#when"
                >
                  <Img 
                    fluid={bgHome[0].node.childImageSharp.fluid}
                    alt={bgHome[0].node.childImageSharp.fluid.originalName}
                    style={{
                      width: "100%",
                      height: "auto",
                      // animation: "1.4s fade-in-top 1s cubic-bezier(0.02, 0.13, 0, 0.99)",
                    }}
                    loading="lazy"
                  />
                </div>
              </Sparkles>
            </div>
            <div
              className="sans-caps"
              data-scroll
              data-scroll-speed="3"
              data-scroll-target="#when"
            >
              Michelle and Tonye had a small wedding celebration! We said "I do", in the eyes of God, family and friends (via livestream) here in Vancouver, BC.
            </div>
          </div>
        </div>
      </div>

      <div className="box-dad">
        <div
          className="box show1"
          data-scroll
          data-scroll-speed="20"
          data-scroll-target="#when"
        ></div>
      </div>

      <div className="box-dad">
        <div
          className="box show2"
          data-scroll
          data-scroll-speed="30"
          data-scroll-target="#when"
        ></div>
      </div>

      <span
        className="number"
        data-scroll
        data-scroll-speed="3"
        onClick={() => window.scroll && window.scroll.scrollTo("#story")}
      >
        W&W
      </span>
    </section>
}

const ImageSupplierWhen = () => {

  // Don't forget to set the size of your image in
  // fluid(maxWidth: 300, quality: 100) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|/"}, 
        sourceInstanceName: {eq: "images"}, 
        name: {eq: "location-wedding"}}, 
        sort: {fields: childrenImageSharp___fluid___originalName, order: ASC}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 340, quality: 100, webpQuality: 80) {
                originalName
                src
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }`);

  return ( allFile.edges );
};