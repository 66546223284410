import React, {useRef, useEffect} from "react";
import {isMobile} from 'react-device-detect';
import LottiePlayer from './../02Molecules/LottiePlayer';


const SectionLoading = React.forwardRef((props, ref) => {

  return (
    <section data-scroll-section className="loading-section" id={props.id} style={{height: "100vh"}} ref={ref}>
       <div data-scroll data-scroll-speed="5" style={{height: "100vh"}}>
         <div data-scroll data-scroll-speed="4" data-scroll-target={"#"+props.id} className="loading-navigation" 
          style={{ width: "60vw", height: 25, background: "#ffffff0e",
            margin: isMobile ? "73px 140px 70px" : "90px 90px 30px"
         }}></div>
         <h1 data-scroll data-scroll-speed="5"
          data-scroll-target={"#"+props.id}
          style={{ color: "#d7cba7", fontSize: 27, height: 100,
            textAlign: isMobile ? "left" : "center",
            padding: isMobile ? "20vw 20vw 20vw 39vw" : "20vw 20vw 10vw",
            fontFamily: 'Lily Script One',
          }}>Loading {props.section}...</h1>
          <span key="OPiNApYrk" className="lottie-wrapper" style={{opacity:0.3}}><LottiePlayer section={props.id}/></span>
       </div>
    </section>
  );
})

export default SectionLoading;
