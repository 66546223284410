import { graphql, useStaticQuery } from "gatsby";

/**
 * Sends a facebook pixel event
 * @param {*} a 
 * @param {*} b 
 */
export default function fbTrack(a,b) {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq(a, b);// eslint-disable-line no-undef
  }
}


/***
 * Checks if the browser is an IOS device - more reliable than css only checks
 */
export function checkIsIOS() {
  let iosQuirkPresent = function () {
      let audio = new Audio();

      audio.volume = 0.5;
      return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
  };

  let isIOS = /iPad|iPhone|iPod/.test(typeof navigator !== "undefined" ? navigator.userAgent : null);
  let isAppleDevice = typeof navigator !== "undefined" && navigator.userAgent.includes('Macintosh');
  let isTouchScreen = typeof navigator !== "undefined" && navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
}

/**
 * Uses GatsbyImage and GraphQL to return all images. Used for gallery section
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
export const ImageSupplier = () => {

  // Don't forget to set the size of your image in
  // fluid(maxWidth: 300, quality: 100) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|/"}, 
        sourceInstanceName: {eq: "images"}, 
        name: {regex: "/picture/"}}, 
        sort: {fields: childrenImageSharp___fluid___originalName, order: ASC}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 580, quality: 100) {
                originalName
                src
                srcWebp
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                #...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }`);

  return ( allFile.edges );
};


/**
 * Generates a random INTEGER number e.g. random(1, 5) will return 1,2,3,4 but NEVER 5
 * @param {minimum number to generate} min 
 * @param {maximum number to generate up to} max 
 */
export const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;


/**
 * Returns an array of numbers e.g. range(5) returns [0, 1, 2, 3, 4]
 * @param {*} start 
 * @param {*} end 
 * @param {*} step 
 */
export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};