import Img from 'gatsby-image';
import React, { useRef } from "react";
import { useIntersection } from 'react-use';
import { NavigationCtr } from "../03Organisms/NavigationCtr";
import LottiePlayer from './../02Molecules/LottiePlayer';

export default function SectionGallery(props) {
  const { rootMargin = '100px', threshold = 0, ...rest } = props;
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: rootMargin,
    threshold: threshold,
  });

  const inView = !(intersection && intersection.intersectionRatio > threshold);

  return (
    <section data-scroll-section className="tiles tiles--columns" id="grid3">
      <div className="tiles__wrap" id="gallery">
        <p
          key="yCTwCjxQp"
          className="larger"
          data-scroll
          data-scroll-speed="1"
          data-scroll-target="#grid3"
        >
          Gallery
        </p>
        <span
          key="HxPXpIcdS"
          className="month"
          data-scroll
          data-scroll-speed="1"
          data-scroll-target="#grid3"
        >
          Some of our favorite photos
        </span>

        {!props.isMobile && (
          <NavigationCtr
            key="GgPhiu9-W"
            active="gallery"
            mobileMenu={props.mobileMenu}
            speed="5"
            sticky="true"
            target="#grid3"
          />
        )}

        <span key="H_wmxYdrm" className="lottie-wrapper"><LottiePlayer section="grid3"/></span>
        { (inView && props.images) ?
          <div className="gallery-wrapper" ref={ref}>
            <div key="1" className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid3" data-scroll-direction="vertical"></div>
            <div key="2" className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid3" data-scroll-direction="vertical"></div>
            <div key="3" className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid3" data-scroll-direction="vertical"></div>
            <div key="4" className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid3" data-scroll-direction="vertical"></div>
          </div>
          :
          <div className="gallery-wrapper">
            <div
                key="1"
                className="tiles__line"
                data-scroll
                data-scroll-speed="1"
                data-scroll-target="#grid3"
                data-scroll-direction="vertical"
              >
                {props.images.slice(32, 39).map((i, n) => {
                  return (
                    <div className="expand-gallery" onClick={() => props.openGallery(i, 32 + n)}>
                      <Img
                        key={i.node.childImageSharp.fluid.originalName}
                        className="tiles__line-img"
                        src={i.node.childImageSharp.fluid.src}
                        fluid={i.node.childImageSharp.fluid}
                        alt={i.node.childImageSharp.fluid.originalName}
                        loading="lazy"
                        fadeIn={false}
                      />
                      {/* <ClickToExpand speed="1" /> */}
                    </div>
                  )
                })}
              </div>
            <div
              key="2"
              className="tiles__line"
              data-scroll
              data-scroll-speed="-1"
              data-scroll-target="#grid3"
              data-scroll-direction="vertical"
            >
              {props.images.slice(23, 31).map((i, n) => {
                return <div className="expand-gallery" onClick={() => props.openGallery(i, 23 + n)}>
                  <Img
                    key={i.node.childImageSharp.fluid.originalName}
                    className="tiles__line-img"
                    src={i.node.childImageSharp.fluid.src}
                    fluid={i.node.childImageSharp.fluid}
                    alt={i.node.childImageSharp.fluid.originalName}
                    loading="lazy"
                    fadeIn={false}
                  />
                  {/* <ClickToExpand speed="-1" /> */}
                </div>
              })}
            </div>
            <div
              key="3"
              className="tiles__line"
              data-scroll
              data-scroll-speed="1"
              data-scroll-target="#grid3"
              data-scroll-direction="vertical"
            >
              {props.images.slice(35, 42).map((i, n) => {
                return <div className="expand-gallery" onClick={() => props.openGallery(i, 37 + n)}>
                  <Img
                    key={i.node.childImageSharp.fluid.originalName}
                    className="tiles__line-img"
                    src={i.node.childImageSharp.fluid.src}
                    fluid={i.node.childImageSharp.fluid}
                    alt={i.node.childImageSharp.fluid.originalName}
                    loading="lazy"
                    fadeIn={false}
                  />
                  {/* <ClickToExpand speed="1" /> */}
                </div>
              })}
            </div>
            <div
              key="4"
              className="tiles__line"
              data-scroll
              data-scroll-speed="-1"
              data-scroll-target="#grid3"
              data-scroll-direction="vertical"
            >
              {props.images.slice(28, 35).map((i, n) => {
                return <div className="expand-gallery" onClick={() => props.openGallery(i, 28 + n)}>
                  <Img
                    key={i.node.childImageSharp.fluid.originalName}
                    className="tiles__line-img"
                    src={i.node.childImageSharp.fluid.src}
                    fluid={i.node.childImageSharp.fluid}
                    alt={i.node.childImageSharp.fluid.originalName}
                    loading="lazy"
                    fadeIn={false}
                  />
                  {/* <ClickToExpand speed="-1" /> */}
                </div>
              })}
            </div>
          </div> 
        }

        <span
          key="Uhq5vgGz0"
          className="number"
          data-scroll
          data-scroll-speed="3"
          onClick={() => window.scroll && window.scroll.scrollTo("#footer")}
        >
          G
        </span>
      </div>
    </section>
  )
}
