import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { TonyeGallery } from "../03Organisms/TonyeGallery";
import { ImageSupplier } from "../_helpers";
import SectionFooter from "./SectionFooter";
import SectionGallery from "./SectionGallery";
import SectionHome from "./SectionHome";
import SectionLoading from "./SectionLoading";
import SectionRegistry from "./SectionRegistry";
import SectionStory from "./SectionStory";
import SectionWhen from "./SectionWhen";
import SectionLivestream from "./SectionLivestream";
import { isMobile } from 'react-device-detect';
import Cursor from "../01Atoms/cursor";

export default function SectionAll(props) {
  const images = ImageSupplier()
  const [gallery, setGallery] = useState(false)
  const [gImg, setGImg] = useState(null)
  const [index, setIndex] = useState(null)

  // console.log("images", images);
  // console.log("index", index);

  function openGallery(image, index) {
    setGallery(!gallery)
    setGImg(image.node.childImageSharp.fluid)
    setIndex(index)
  }

  console.log("images", images);

  return (
    <>
      {!isMobile && <Cursor /> }
      <SectionHome 
        size={props.size} 
        mobileMenu={props.mobileMenu} 
      />

      <SectionWhen
        fallback={<SectionLoading section="When & Where" id="when"  />}
        isMobile={props.isMobile}
        mobileMenu={props.mobileMenu}
      />

      <SectionStory
        fallback={<SectionLoading section="Our Story" id="story"  />}
        isMobile={props.isMobile}
        mobileMenu={props.mobileMenu}
        images={images}
      />
        
      <SectionRegistry
        fallback={<SectionLoading section="Registry" id="registry"  />}
        isMobile={props.isMobile}
        mobileMenu={props.mobileMenu}
      />
      
      <SectionLivestream
        fallback={<SectionLoading section="Registry" id="registry"  />}
        isMobile={props.isMobile}
        mobileMenu={props.mobileMenu}
      />
    
      <SectionGallery
        fallback={<SectionLoading section="Gallery" id="gallery"  />}
        isMobile={props.isMobile}
        mobileMenu={props.mobileMenu}
        images={images}
        openGallery={openGallery}
      />
        
      <SectionFooter 
        fallback={<SectionLoading section="Gallery" id="footer"  />}
      />
        
      <AnimatePresence initial={false}>
        {gallery && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TonyeGallery
              images={images}
              gallery={gallery}
              setGallery={setGallery}
              gImg={gImg}
              setGImg={setGImg}
              index={index}
              setIndex={setIndex}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
