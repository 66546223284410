import React, { useRef } from 'react';
import galleryGif from '../../../static/images/gallery.gif';
import registryGif from '../../../static/images/registry2.gif';
import { useIntersection } from 'react-use';

function LottiePlayer (props) {


  function animationData() {   
      let section = props.section;
      
      switch(true) {
        case String(section).endsWith("loading"): {
          return galleryGif;
        }
        
        case String(section).endsWith("registry"): {
          return registryGif;
        }
        
        case String(section).endsWith("grid3"):
        case String(section).endsWith("gallery"): {
          return galleryGif;
        }
        
        
        default: {
          return galleryGif;
        }
      }
    }


    const ref = useRef(null);
    const { rootMargin = '100px', threshold = 0, ...rest } = props;
    const intersection = useIntersection(ref, {
      root: null,
      rootMargin: rootMargin,
      threshold: threshold,
    });

    const inView = !(intersection && intersection.intersectionRatio > threshold);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData(),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <>{ inView ?
      <span className="happy" data-scroll data-scroll-speed="7" data-scroll-target={"#" + props.section} ref={ref}></span>
      :
      <span className="happy" data-scroll data-scroll-speed="7" data-scroll-target={"#" + props.section}>
        <img 
          play
          loop
          autoPlay
          // animationData={animationData()}
          src={animationData()}
          // rendererSettings={{
          //   preserveAspectRatio: 'xMidYMid slice'
          // }}
          style={{ 
            width: 490, 
            height: "auto",
            overflow: "hidden",
            margin: "0 auto",
            padding: "4px 5px 6px",
            outline: "none"
          }}
        />
      </span>
    }
    </>
}

export default LottiePlayer;