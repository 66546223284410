import React from "react";
import { MenuItems } from "./NavigationMenuItems";

export const NavigationCtr = props => (
  <div className={`navigation-cmp ${props.mobileMenu ? "open" : ""}`}>
    {true && (
      <div
        className="navigation"
        data-scroll
        // data-scroll-sticky={props.sticky}
        // data-scroll-target={props.target}
        data-scroll-speed={props.speed || "2"}
        // data-scroll-offset="120"
        data-scroll-direction={props.direction || "vertical"}
      >
        {/* <div className="navigation-backdrop"></div> */}
        {true && <MenuItems active={props.active} />}
      </div>
    )}
  </div>
)
