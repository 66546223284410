import React from "react";
import { isTablet } from 'react-device-detect';


export const Navigation = props => (
  <div
    // data-scroll
    // data-scroll-speed="2"
    // data-scroll-direction="vertical"
    // data-scroll-sticky
    // data-scroll-target="#yah"
    className="navigation-container"
  >

    { true && (//!props.mobileMenu && (
      <>
        <div
          className={`initials ${props.mobileMenu && "menu-open"}`}
          onClick={() => window.scroll && window.scroll.scrollTo("#home")}
        >
          <span className="piece">M</span>
          <span className="piece">
            {/* <i className="lar la-heart"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style={{transform: "rotate(360deg)" }}preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 20a1 1 0 0 1-.561-.172c-.225-.151-5.508-3.73-7.146-5.371C2.462 12.626 2.25 10.68 2.25 9.375A5.38 5.38 0 0 1 7.625 4c1.802 0 3.398.891 4.375 2.256A5.373 5.373 0 0 1 16.375 4a5.38 5.38 0 0 1 5.375 5.375c0 1.305-.212 3.251-2.043 5.082c-1.641 1.641-6.923 5.22-7.146 5.371A1 1 0 0 1 12 20zM7.625 6A3.379 3.379 0 0 0 4.25 9.375c0 1.093.173 2.384 1.457 3.668c1.212 1.212 4.883 3.775 6.293 4.746c1.41-.971 5.081-3.534 6.293-4.746c1.284-1.284 1.457-2.575 1.457-3.668C19.75 7.514 18.236 6 16.375 6S13 7.514 13 9.375a1 1 0 1 1-2 0A3.379 3.379 0 0 0 7.625 6z" fill="#e6d9b2"/></svg>
          </span>
          <span className="piece">T</span>

          <div className="go-home">
            Scroll Up <i className="las la-arrow-right"></i>
          </div>
        </div>

        <div
          className="date"
          onClick={() => window.scroll && window.scroll.scrollTo("#when")}
        >
           <style
            dangerouslySetInnerHTML={{
              __html: [
                ".scroll-container .navigation-container .date:after {",
                ` bottom: ${isTablet ? "-65vh" : "-60.5vh"}`,
                "}",
              ].join("\n"),
            }}
          ></style>
          <span className="piece">03</span>
          <span className="piece">/</span>
          <span className="piece">19</span>
        </div>
      </>
    )}
  </div>
);
