import React from "react";
import { isMobileOnly } from 'react-device-detect';

function redirect_blank(url) {
  var a = document.createElement('a');
  a.target="_blank";
  a.href=url;
  a.click();
}

export const MenuItems = props => (
  <>
    <span
      key="mn1"
      className={`item ${props.active === "when" ? "active" : ""}`}
      href="#when"
      onClick={() => window.scroll && window.scroll.scrollTo("#when")}
    >
      When &amp; Where
    </span>
    <span
      key="mn2"
      className={`item ${props.active === "story" ? "active" : ""}`}
      href="#story"
      onClick={() => window.scroll && window.scroll.scrollTo("#story")}
    >
      Our Story
    </span>
    { !isMobileOnly && 
      <span
        key="mn3"
        className={`item ${props.active === "registry" ? "active" : ""}`}
        onClick={() => window.scroll && window.scroll.scrollTo("#registry")}
      >
        Registry
      </span>
    }
    <span
      key="mn5"
      className={`item ${props.active === "livestream" ? "active" : ""}`}
      href="https://michelletonye.app.rsvpify.com/"
      target="_blank"
      onClick={() => window.scroll && window.scroll.scrollTo("#livestream")}
      // onClick={() => redirect_blank("https://michelletonye.app.rsvpify.com/")}
    >
      Livestream
    </span>
    <span
      key="mn4"
      className={`item ${props.active === "gallery" ? "active" : ""}`}
      onClick={() => window.scroll && window.scroll.scrollTo("#gallery")}
    >
      Gallery
    </span>
    {/* <span
      key="mn6"
      className={`item ${props.active === "rsvp" ? "active" : ""}`}
      href="https://michelletonye.app.rsvpify.com/"
      target="_blank"
      onClick={() => redirect_blank("https://michelletonye.app.rsvpify.com/")}
    >
      RSVP
    </span> */}
  </>
)
