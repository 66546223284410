import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import Tilty from 'react-tilty';
import {isMobile} from 'react-device-detect';
import Particles1 from "./Particles1";
import Img from 'gatsby-image';

export function TonyeGallery({
      gallery, setGallery,
      images,
      index, setIndex,
      gImg, setGImg
}){

  

  useEffect(() => {
    setLoaded(false);
    function downHandler({ key }) {
      if (key === 'Escape' || key === 'Esc') {
        setGallery(false);
        key = null;
      }
      if (key === 'ArrowRight') {
        goRight(images, -1);
        key = null;
      }
      if (key === 'ArrowLeft') {
        goLeft(images, 1);
        key = null;
      }
    }

    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [gImg]);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? (isMobile? 777: 2000) : (isMobile? -777: -2000),
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 2000 : -2000,
        opacity: 0
      };
    }
  };

  // const swipeConfidenceThreshold = 10000;
  const swipeConfidenceThreshold = 3000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const [loaded, setLoaded] = useState(false);
  const [left, setLeft] = useState(false);

  const [[page, direction], setPage] = useState([0, 0]);

  function goRight(images, newDirection) {
    setLoaded(false)
    if (index + 1 < images.length) {
      // setGImg(null)
      setIndex(index + 1)
      // setGImg(images[index + 1].node.childImageSharp.fluid.src)
      setGImg(images[index + 1].node.childImageSharp.fluid)
      setLeft(false);
      setLoaded(true);
      setPage([page + newDirection, newDirection]);
    }
  }

  function goLeft(images, newDirection) {
    setLoaded(false)
    if (index - 1 >= 0) {
      // setGImg(null)
      setIndex(index - 1)
      // setGImg(images[index - 1].node.childImageSharp.fluid.src)
      setGImg(images[index - 1].node.childImageSharp.fluid)
      setLeft(true);
      setLoaded(true);
      setPage([page + newDirection, newDirection]);
    }
  }
  
  // console.log("loaded", images);
  console.log("index", index);
  console.log("gImg", gImg);
  // console.log("direction", direction);
      
  return <div className="tonye-michelle">
    <AnimatePresence initial={false} custom={direction}>
      <div 
        key={images[index].node.childImageSharp.fluid.originalName}
        className="tonye-gallery"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}

      >
      { typeof window !== 'undefined' &&
        <Tilty
          className="tonye-gallery-inner"
          style={{
            transformStyle: "preserve-3d",
            position: "absolute !important",
            transition: "transform 0.3s ease-in-out"
          }}
          perspective={900}
          max={14}
          reset={false}
        >
            <style
              dangerouslySetInnerHTML={{
                __html: [
                  ".tonye-gallery-inner:before {",
                  '  content: "";',
                  `  background-image: url("${images[index].node.childImageSharp.fluid.srcWebp}");`,
                  `  touch-action: none;`,
                  `  pointer-events: none;`,
                  `  transform: translateZ(157px);`,
                  `  z-index: 1;`,
                  "}",
                ].join("\n"),
              }}
            ></style>
            {/* {(!loaded && (index>0 && index<images.length)) && <span style={{color:"#bc4f25", marginTop: -20}}>Loading our pictures :)</span>} */}
            {/* <motion.img
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              draggable
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) { 
                  goRight(images, 1);
                } else if (swipe > swipeConfidenceThreshold) {
                  goLeft(images, -1);
                }
              }}
              className={`big-image ${loaded && (left ? "go-left" : "go-right")}`}
              src={gImg}
              alt={
                images[index].node.childImageSharp.fluid.originalName
              }
              onLoad={() => setLoaded(true)}
            /> */}
            <motion.span
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              draggable
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) { 
                  goRight(images, 1);
                } else if (swipe > swipeConfidenceThreshold) {
                  goLeft(images, -1);
                }
              }}
              key={
                images[index].node.childImageSharp.fluid.originalName
              }
            >
              {//!loaded ? "loading" :  
              <Img
                className={`big-image ${loaded && (left ? "go-left" : "go-right")}`}
                fluid={gImg}
                alt={
                  images[index].node.childImageSharp.fluid.originalName
                }
                key={
                  images[index].node.childImageSharp.fluid.originalName
                }
                // onLoad={() => setLoaded(true)}
              />}
            </motion.span>
      </Tilty>
      }
    </div> 
    
  </AnimatePresence>

  <i
    className={`las la-chevron-circle-left chevron left ${index <= 0 ? "nope" : ""}`}
    onClick={() => goLeft(images, -1)}
  />
  <i
    className={`las la-chevron-circle-right chevron right ${index === images.length-1 ? "nope" : ""}`}
    onClick={() => goRight(images, 1)}
  />
  <i
    className="las la-times"
    onClick={() => setGallery(false)}
  >✕</i>

  </div>
}
